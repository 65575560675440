import React from 'react'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'
import { H3, H6, Text, PageContainer, ContentContainer } from '@system'
import font from '@helpers/font'
import { monochrome } from '@helpers/palette'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  outer: {
    background: (props) =>
      props.gradient
        ? theme.palette.gradient.background
        : props.bgColor && monochrome(props.bgColor.color),
    backgroundImage: (props) =>
      props.backgroundImage &&
      props.backgroundImage.length > 0 &&
      `${theme.palette.gradient.background}, url(${props.backgroundImage[0].image})`,
    backgroundSize: 'cover',
    width: '100%',
  },
  inner: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '2rem 0 0',
    [theme.breakpoints.up('sm')]: {
      padding: '4rem 0',
    },
  },
  eyebrow: {
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '1rem 0',
    color: (props) =>
      (props.gradient && theme.palette.text.white) ||
      theme.palette.text.tertiary,
  },
  headline: {
    color: ({ color }) =>
      color?.color ? monochrome(color.color) : theme.palette.text.primary,
    textAlign: 'center',
    padding: '0 1rem',
    display: 'block',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      padding: '0 3rem',
    },
  },
  description: {
    color: (props) =>
      (props.gradient && theme.palette.text.white) ||
      theme.palette.text.tertiary,
    textAlign: 'center',
    padding: '0 1rem',
    display: 'block',
    ...makeResponsiveFontSizes(24, 15),
  },
  button: {
    textAlign: 'center',
    '& button': {
      height: '30px',
      padding: '5px 15px',
      [theme.breakpoints.up('sm')]: {
        height: '39px',
        padding: '12px 18px',
      },
    },
    '& button .material-icons': {
      fontSize: '16px',
      padding: '0px 5px',
      [theme.breakpoints.up('sm')]: {
        padding: '0px 12px',
      },
    },
  },
  imageContainer: {
    display: 'flex',
    width: (props) => `${props.imageProportion}%`,
  },
}))

const HeadlineDescriptionWithCta = (props) => {
  const {
    button,
    description,
    eyebrowLabel,
    headline,
    image,
    isFullWidth,
    imageProportion,
    variant,
    shouldRenderH1,
  } = props.blok

  const gradient = variant === 'gradient'
  const classes = useStyles({ ...props.blok, gradient })
  const imageStyle = {
    width: `${imageProportion}%`,
  }

  return (
    <SbEditable content={props.blok}>
      <PageContainer
        parentWidth={isFullWidth}
        styles={{
          backgroundColor:
            props?.bgColor?.color === 'transparent' ? 'transparent' : null,
        }}
      >
        <Box className={classes.outer}>
          <ContentContainer>
            <Box className={classes.inner}>
              {eyebrowLabel ? (
                <H6 className={classes.eyebrow}>{eyebrowLabel}</H6>
              ) : null}
              {headline ? (
                <H3
                  component={shouldRenderH1 ? 'h1' : 'h3'}
                  className={classes.headline}
                >
                  {headline}
                </H3>
              ) : null}
              {description ? (
                <Text className={classes.description}>{description}</Text>
              ) : null}
              {button ? (
                <Typography display="block" className={classes.button}>
                  {renderBloks(button)}
                </Typography>
              ) : null}
              {image && image.length > 0 ? (
                <Box className={classes.imageContainer}>
                  {renderBloks(image, { style: imageStyle })}
                </Box>
              ) : null}
            </Box>
          </ContentContainer>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default HeadlineDescriptionWithCta
